<template>
  <div class="home">
	  <Header></Header>
       <div class="update-record-main-box">
			<div class="product01-box">
				<div class="two-nav-box">
					<div class="go-up-logo" @click="goProv">
						<img src="../assets/img/go-up-logo.png" alt="">
					</div>
                    <div class="select-box">
						<div class="left-img">
							<img src="../assets/img/select-btn.png" alt="">
						</div>
						<div class="right-input">
							<input type="text" v-model="searchTextInput" @change="selectFn" placeholder="搜索" class="input">
						</div>
					</div>
				</div>
				<div class="top-p">
                    <div class="big-tit">#{{selectSearchText}}#</div>
                    <div class="select-num">共计 {{allMsgNum}} 条相关结果</div>
					<div class="bottom-two-nav">
						<p class="p1" @click="goProv">最新消息</p>
						<p class="shu">|</p>
						<p class="p2">搜索结果</p>
					</div>
                    <!-- <div class="about-msg">
                        <span class="name">相似词条</span>
                        <p class="btn">YOGA</p>
                        <p class="btn">OTA</p>
                        <p class="btn">Pad Pro</p>
                    </div> -->
				</div>
				<div class="product01-content-box">
					<div class="product01-content">
						<div class="left-content" v-if="allMsg.length>0">
							<div class="content-slide" v-for="(item,index) in allMsg" :key="index"  @click="goDetails(item.id)">
								<div class="left-text">
									<div class="top-name">{{item.name}}</div>
									<div class="time-logo">
										<img class="img" src="../assets/img/update-time-logo.png" alt="">
										<p class="time">{{timestampToTime(item.metadataInfo.CUSTOMER_PORTAL_PUBLISH_TIME)}}</p>
									</div>
								</div>
								<div class="right-img">
									<img :src="item.coverPicture" alt="">
								</div>
							</div>
						</div>
                        <div class="left-content" v-else>
							暂无数据
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
  </div>
</template>
<script>
//设置html font-size
document.documentElement.style.fontSize =
  document.documentElement.clientWidth / 7.5 + "px";
window.onresize = () => {
  document.documentElement.style.fontSize =
    document.documentElement.clientWidth / 7.5 + "px";
};
/* 
var blockIdArr={
    335 最新消息Banner
    327 最新消息>内容详情
    362 更新信息>机型图
    334 更新记录>内容详情
    333 内测机型Banner
    366 内侧机型>机型列表
    363 二维码-微信
    364 二维码-微博
    indexBanner:335,
    newMsg:327,
    newsjixing:362,
    update:334,
    neicebanner:333,
    neicejixing:366,
    vxcode:363,
    wbcode:364
}
*/
//接口地址引入
import interfaces from "../utils/baseUrl";
import axios from 'axios'
import Header from "../components/Header"
import Footer from "../components/Footer"
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
       allMsg:[],
       pageNum:1,
       pageSize:8,
	   searchText:"",
	   selectSearchText:"",
       rightMoreArr:{
		   records:[]
	   },
       morePageNum:1,
	   morePageSize:5,
	   allMsgNum:0,
	   searchTextInput:'',
    }
  },
  beforeMount: function () {
    //获取链接参数
    var query = this.$route.query;
    //设置整体展厅参数
    // // this.cacheKey = query.cacheKey;
	// this.getContent({"id": query.searchText})
	this.searchText=query.searchText
	this.selectSearchText=query.searchText
    var selectMsg={
      "blockId": interfaces.blockIdArr.newMsg,
      "pageNum":this.pageNum,
      "pageSize":this.pageSize,
      "searchText":this.searchText,
      "filterConditionList":[]
    }
    this.searchFn(selectMsg);
    //获取右侧
    this.searchMoreFn({
        "blockId": interfaces.blockIdArr.newMsg,
        "pageNum":this.morePageNum,
        "pageSize":this.morePageSize,
        "searchText":"",
        "filterConditionList":[]
    })

  },
  //初始化执行
  created: function () {
    let $this = this;
    // $this.getBaseInfo(335)
  },
  //事件
  methods: {
	  seeMore(){
			var $this = this;
			$this.morePageNum=$this.morePageNum++
		this.searchMoreFn({
				"blockId": interfaces.blockIdArr.newMsg,
				"pageNum":$this.morePageNum,
				"pageSize":$this.morePageSize,
				"searchText":"",
				"filterConditionList":[]
			})
	  },

          //获取导航
   getBaseInfo(id){
        var $this = this;
        var config = {
          method: "get",
          url: `${interfaces.baseUrl}/portal/open/block/public/zui/getBaseInfo/${id}`,
          headers:interfaces.headers(),
        };
  
       axios(config)
          .then(function (response) {
            return response.data
          })
          .catch(function (error) {
            return error
          });
    },
     //搜索内容区块中内容
    searchFn(data){
        var $this = this;
        var config = {
            method: "post",
            url: `${interfaces.baseUrl}/portal/open/block/search`,
            headers:interfaces.postHeaders(),
            data,
            };
    
           axios(config)
            .then(function (response) {
                $this.allMsg=response.data.result.records;
				console.log($this.allMsg)
				$this.allMsgNum=response.data.result.totalCount;
				$this.selectSearchText=$this.searchText
            })
            .catch(function (error) {
                return error
            });
      },
       //获取右侧内容
        searchMoreFn(data){
            var $this = this;
            var config = {
                method: "post",
                url: `${interfaces.baseUrl}/portal/open/block/search`,
                headers:interfaces.postHeaders(),
                data,
                };
        
            axios(config)
                .then(function (response) {
                       $this.rightMoreArr=response.data.result;
                })
                .catch(function (error) {
                    return error
                });
        },
        timestampToTime(timestamp) {
            var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '年';
            var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '月';
            var D = date.getDate() + '日';
            return Y+M+D;
        },
        selectFn(){
            var selectMsg={
                "blockId": interfaces.blockIdArr.newMsg,
                "pageNum":this.pageNum,
                "pageSize":this.pageSize,
                "searchText":this.searchText,
                "filterConditionList":[]
			}
			this.searchText=this.searchTextInput
            this.searchFn(selectMsg)
        },
         goDetails(id){
          console.log(id);
          this.$router.push({
            name: 'newsdetails',
            query:{
              id:id
            }
          })
		},
		 goProv(id){
          this.$router.go(-1)
        },
  },
};
</script>
<style lang="less" scoped>
.footer-box{
	background: #ffffff !important;
}
body{
	overflow: auto;
	background: #f5f5f6;
}
.update-record-main-box{
	width: 100%;
	min-height: calc(100vh - 3rem);
	overflow: hidden;
	position: relative;
	background: #f5f5f6;
}
.update-record-main-box img{
	display: block;
	width: 100%;
}
.update-record-main-box .product01-box{
	width: 100%;
	margin-top: .95rem;
	padding-bottom: .6rem;
	position: relative;
	background: #f5f5f6;
	overflow: hidden;
}
.update-record-main-box .product01-box .top-p{
	width:100%;
	background: #ffffff;
	padding:.58rem .34rem;
	box-sizing: border-box;
	margin: 0 auto;
    // margin-top:46px;
    text-align: left;
}
.update-record-main-box .product01-box .top-p .big-tit{
	font-size: .4rem;
	color: #5a596c;
}
.update-record-main-box .product01-box .top-p .select-num{
	color:#acacb5;
	font-size: .24rem;
	margin-top:.2rem;
}
.update-record-main-box .product01-box .top-p .bottom-two-nav{
	margin-top: .25rem;
    font-size:.18rem;
}
.update-record-main-box .product01-box .top-p .bottom-two-nav .p1{
	float: left;
	color:#c7c7c7;
}
.update-record-main-box .product01-box .top-p .bottom-two-nav .shu{
	float: left;
	margin-left: .15rem;
}
.update-record-main-box .product01-box .top-p .bottom-two-nav .p2{
	float: left;
	margin-left: .15rem;
	color: #0d0c22;
}
.update-record-main-box .product01-box .top-p .about-msg{
	font-size: 12px;
	color: #acacb5;
	margin-top: 7.5px;
}
.update-record-main-box .product01-box .top-p .about-msg .name{
	display: inline-block;
}
.update-record-main-box .product01-box .top-p .about-msg .btn{
	padding: 3.5px 10px;
	border-radius:17px;
	border: 1px solid #5a596c;
	height: 17px;
	line-height: 17px;
	text-align: center;
	display: inline-block;
	color: #5a596c;
	margin-left: 10px;
}
.update-record-main-box .product01-box .two-nav-box{
	width: 100%;
	// margin:20px auto 0 auto;
	height:1.69rem;
	padding-bottom:10px;
	box-sizing: border-box;
	overflow: hidden;
	background: #765ec1;
	color: #c7c7c7;
	font-size: 14px;
}
.update-record-main-box .product01-box .two-nav-box .btn-box{
	float: left;
	width:62px;
}
.update-record-main-box .product01-box .two-nav-box .active-btn{
	color:#0d0c22;
}
.update-record-main-box .product01-box .two-nav-box .shu{
	float: left;
	padding-right: 10px;
}
.update-record-main-box .product01-box .product01-content-box{
	position: relative;
	width: 100%;
	margin: 0 auto;
	// margin-top:.15rem;
	overflow: hidden;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box{
	width:7.2rem;
	margin: 0 auto;
	height:.56rem;
	line-height: .56rem;
	display: flex;
	background:#f5f5f6;
	overflow: auto;
	border-radius: .1rem;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide{
	overflow: hidden;
	// margin-top:13px
	width:1.79rem;
	line-height: .56rem;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide .select-box{
	position: relative;
	width:16px;
	height: 16px;
	float: left;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide .select-box .img1{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2;
	display: none;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide-active .select-box .img1{
	display: block;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide .select-box .img2{
	position: absolute;
	top: 50%;
	left: 50%;
	width:10px;
	transform: translate(-50%,-50%);
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide-active{
	background: linear-gradient(to right,#e626f3 0%,#428efe 100%);
	border-radius: .1rem;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide-active .right-p{
	color: #ffffff !important;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide .right-p{
	// float: left;
	// margin-left:5px;
	line-height: .56rem;
	text-align: center;
	color:#333333;
	font-size:.24rem;
}
.update-record-main-box .product01-box .product01-content-box .left-year-box .slide-active .right-p{
	color: #0f0f22;
}
.update-record-main-box .product01-box .product01-content-box .product01-content{
	width: 100%;
	// background: #f5f5f5;
	margin: 0 auto;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content{
	width:7.2rem;
	margin: 0 auto;
	// float: left;
	
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .content-slide{
	width: 100%;
	height:1.8rem;
	margin-top:.15rem;
	background: #ffffff;
	border-radius:.15rem;
	border: 1px solid #dedede;
	padding:.2rem 0 0 .2rem;
	box-sizing: border-box;
	overflow: hidden;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .content-slide2{
	width: 100%;
	height:1.8rem;
	margin-top:.15rem;
	background: #ffffff;
	border-radius:.15rem;
	border: 1px solid #dedede;
	padding:.2rem 0 0 .2rem;
	box-sizing: border-box;
	overflow: hidden;
	line-height: 1.8rem;
	font-size: .32rem;
}
// .update-record-main-box .product01-box .product01-content-box .product01-content .left-content .content-slide:nth-of-type(1){
// 	margin-top: 0;
// }
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .content-slide .left-text{
	float: left;
	width: 4rem;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .content-slide .left-text .top-name{
	// padding:0 .15rem;
	height:.3rem;
	line-height: .3rem;
	text-align: left;
	color: #000000;
	font-size:.3rem;
	line-height: .4rem;
		margin-top:.15rem;
		// line-height: .45rem;
	// border-radius:.3rem;
	// border: 1px solid #7b7a89;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .center-edition{
	margin-top:.25rem;
	color: #5a596c;
	font-size:.3rem;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .time-logo{
	/* display: flex; */
	/* justify-content: center; */
	margin-top: .56rem;
	overflow: hidden;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .time-logo .img{
	width:.2rem;
	float: left;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .time-logo .time{
	margin-left:.1rem;
	float: left;
	line-height:.2rem;
	font-size: .18rem;
	color:#5a596c;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .left-content .content-slide .right-img{
	float: right;
	margin-right:.25rem;
	margin-top:-.07rem;
	width:2.02rem;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .right-see-more{
    float: left;
	width:166px;
	margin-left:14px;
	background: #ffffff;
	border-radius: 5px;
	border: 1px solid #dedede;
	overflow: hidden;
	padding:0 7.5px;
	box-sizing: border-box;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .right-see-more .top-tit{
    width: 100%;
   height:41.5px;
   line-height: 41.5px;
   text-align: center;
   color: #999999;
   font-size: 18px;
   border-bottom: 1px solid #dedede;

}
.update-record-main-box .product01-box .product01-content-box .product01-content .right-see-more .see-more-content{
	margin-top:7.5px;
	padding-bottom: 7.5px;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .right-see-more .see-more-content .more-a{
	display: block;
	width: 100%;
	text-align: center;
	color: #0d0c22;
	font-size: 14px;
    margin-top: 5px;
	cursor: pointer;
	overflow:hidden; /*超出的部分隐藏起来。*/ 
	white-space:nowrap;/*不显示的地方用省略号...代替*/
	text-overflow:ellipsis;/* 支持 IE */
}
.update-record-main-box .product01-box .product01-content-box .product01-content .right-see-more .see-more-content .more-a:hover{
	 text-decoration:underline;
}
.update-record-main-box .product01-box .product01-content-box .product01-content .right-see-more .btn-more{
	width: 100%;
	text-align: center;
	color:#999999;
	font-size: 16px;
	height:28px;
	line-height:28px;
	border-top: 1px solid #dedede;
}

.update-record-main-box .product01-box .go-up-logo{
	position: absolute;
	top:.45rem;
	left: .39rem;
	width:.19rem;
}
.update-record-main-box .product01-box .select-box{
	  position:absolute;
  top:.3rem;
  left: .9rem;
   width:5.7rem;
   height: .6rem;
   line-height: .6rem;
   display: flex;
   justify-content: center;
   border: 1px solid #dfe1e5;
   border-radius: .6rem;
   box-sizing: border-box;
   align-items: center;
   overflow: hidden;
   background: #ffffff;
}
.update-record-main-box .product01-box .select-box .left-img{
   width:.28rem;
  height:.28rem;
	// height: 14px;
	display: block;
}
.update-record-main-box .product01-box .select-box .left-img img{
  width:.28rem;
  height:.28rem;
    display: block;
}
.update-record-main-box .product01-box .select-box .right-input{
	background: none;
	margin-left: .15rem;
	color: #000000;
	font-size: .24rem;
	width:4.9rem;
}
.update-record-main-box .product01-box .select-box input::-webkit-input-placeholder {
    color:#dfe1e5;
}
.update-record-main-box .product01-box .select-box input::-moz-placeholder { 
    color: #dfe1e5;
}
.update-record-main-box .product01-box .select-box input:-ms-input-placeholder {
    color: #dfe1e5;
}
.update-record-main-box .product01-box .select-box input:-moz-placeholder {
    color: #dfe1e5;
}
</style>